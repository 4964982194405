<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="12" sm="6">
        <span class="text-body-2">Self pickup orders for today - {{ getCurrentDate }}</span>
        <v-card class="mt-2">
          <v-data-table
            mobile-breakpoint="0"
            :headers="getHeaders"
            :items="selfPickupOrders"
            disable-pagination
            disable-sorting
            disable-filtering
            hide-default-footer
            show-expand
            class="text-caption mobile-table rounded"
          >
            <template v-slot:item.order_status="{ item }">
              <v-chip
                :color="getColor(item.order_status.name)"
                class="justify-center chips white--text px-1"
                label
                small
              >
                {{ item.order_status.name }}
              </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pt-2 pb-4 px-2">
                <p class="mb-1"><strong>Customer:</strong> {{ item.customer_name }} ({{ item.customer_number }})</p>
                <p v-if="item.booking" class="mb-1">
                  <strong>Delivery date:</strong> 
                  {{ new Date(item.booking.slot.slot_date).toLocaleDateString() + ` (${item.booking.slot.slot_time})` }}
                </p>
                <p class="mb-1"><strong>Delivery method:</strong> {{ item.delivery_method }}</p>
                <p class="mb-1"><strong>Payment method:</strong> {{ item.payment_method }}</p>
                <p v-if="notesExist(item)" class="mb-1"><strong>Notes:</strong> {{ item.notes }}</p>

                <div class="d-flex align-center mt-5">
                  <v-btn
                    depressed
                    color="blue"
                    class="white--text"
                    small
                    @click="updateOrderDialog(item.id)"
                  >
                    <span>View</span>
                  </v-btn>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-pagination
          v-model="paginationPickup.current_page"
          :length="paginationPickup.total_page"
          :total-visible="7"
          @input="fetchSelfPickupOrders(paginationPickup.current_page)"
          class="float-right mt-2"
          color="primary"
        >
        </v-pagination>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="text-body-2">Delivery orders for today - {{ getCurrentDate }}</span>
        <v-card class="mt-2">
          <v-data-table
            mobile-breakpoint="0"
            :headers="getHeaders"
            :items="deliveryOrders"
            disable-pagination
            disable-sorting
            disable-filtering
            hide-default-footer
            show-expand
            class="text-caption mobile-table rounded"
          >
            <template v-slot:item.order_status="{ item }">
              <v-chip
                :color="getColor(item.order_status.name)"
                class="justify-center chips white--text px-1"
                label
                small
              >
                {{ item.order_status.name }}
              </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pt-2 pb-4 px-2">
                <p class="mb-1"><strong>Customer:</strong> {{ item.customer_name }} ({{ item.customer_number }})</p>
                <p v-if="item.delivery_address != ''" class="mb-1"><strong>Address:</strong> {{ item.delivery_address }}</p>
                <p v-if="item.booking" class="mb-1">
                  <strong>Delivery date:</strong> 
                  {{ new Date(item.booking.slot.slot_date).toLocaleDateString() + ` (${item.booking.slot.slot_time})` }}
                </p>
                <p class="mb-1"><strong>Delivery method:</strong> {{ item.delivery_method }}</p>
                <p class="mb-1"><strong>Payment method:</strong> {{ item.payment_method }}</p>
                <p v-if="notesExist(item)" class="mb-1"><strong>Notes:</strong> {{ item.notes }}</p>

                <div class="d-flex align-center mt-5">
                  <v-btn
                    depressed
                    color="blue"
                    class="white--text"
                    small
                    @click="updateOrderDialog(item.id)"
                  >
                    <span>View</span>
                  </v-btn>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-pagination
          v-model="paginationDelivery.current_page"
          :length="paginationDelivery.total_page"
          :total-visible="7"
          @input="fetchDeliveryOrders(paginationDelivery.current_page)"
          class="float-right mt-2"
          color="primary"
        >
        </v-pagination>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="text-body-2">Self pickup orders for tomorrow - {{ getTmrDate }}</span>
        <v-card class="mt-2">
          <v-data-table
            mobile-breakpoint="0"
            :headers="getHeaders"
            :items="tmrSelfPickupOrders"
            disable-pagination
            disable-sorting
            disable-filtering
            hide-default-footer
            show-expand
            class="text-caption mobile-table rounded"
          >
            <template v-slot:item.order_status="{ item }">
              <v-chip
                :color="getColor(item.order_status.name)"
                class="justify-center chips white--text px-1"
                label
                small
              >
                {{ item.order_status.name }}
              </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pt-2 pb-4 px-2">
                <p class="mb-1"><strong>Customer:</strong> {{ item.customer_name }} ({{ item.customer_number }})</p>
                <p v-if="item.booking" class="mb-1">
                  <strong>Delivery date:</strong> 
                  {{ new Date(item.booking.slot.slot_date).toLocaleDateString() + ` (${item.booking.slot.slot_time})` }}
                </p>
                <p class="mb-1"><strong>Delivery method:</strong> {{ item.delivery_method }}</p>
                <p class="mb-1"><strong>Payment method:</strong> {{ item.payment_method }}</p>
                <p v-if="notesExist(item)" class="mb-1"><strong>Notes:</strong> {{ item.notes }}</p>

                <div class="d-flex align-center mt-5">
                  <v-btn
                    depressed
                    color="blue"
                    class="white--text"
                    small
                    @click="updateOrderDialog(item.id)"
                  >
                    <span>View</span>
                  </v-btn>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-pagination
          v-model="paginationPickupTmr.current_page"
          :length="paginationPickupTmr.total_page"
          :total-visible="7"
          @input="fetchTmrSelfPickupOrders(paginationPickupTmr.current_page)"
          class="float-right mt-2"
          color="primary"
        >
        </v-pagination>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="text-body-2">Delivery orders for tomorrow - {{ getTmrDate }}</span>
        <v-card class="mt-2">
          <v-data-table
            mobile-breakpoint="0"
            :headers="getHeaders"
            :items="tmrDeliveryOrders"
            disable-pagination
            disable-sorting
            disable-filtering
            hide-default-footer
            show-expand
            class="text-caption mobile-table rounded"
          >
            <template v-slot:item.order_status="{ item }">
              <v-chip
                :color="getColor(item.order_status.name)"
                class="justify-center chips white--text px-1"
                label
                small
              >
                {{ item.order_status.name }}
              </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pt-2 pb-4 px-2">
                <p class="mb-1"><strong>Customer:</strong> {{ item.customer_name }} ({{ item.customer_number }})</p>
                <p v-if="item.delivery_address != ''" class="mb-1"><strong>Address:</strong> {{ item.delivery_address }}</p>
                <p v-if="item.booking" class="mb-1">
                  <strong>Delivery date:</strong> 
                  {{ new Date(item.booking.slot.slot_date).toLocaleDateString() + ` (${item.booking.slot.slot_time})` }}
                </p>
                <p class="mb-1"><strong>Delivery method:</strong> {{ item.delivery_method }}</p>
                <p class="mb-1"><strong>Payment method:</strong> {{ item.payment_method }}</p>
                <p v-if="notesExist(item)" class="mb-1"><strong>Notes:</strong> {{ item.notes }}</p>

                <div class="d-flex align-center mt-5">
                  <v-btn
                    depressed
                    color="blue"
                    class="white--text"
                    small
                    @click="updateOrderDialog(item.id)"
                  >
                    <span>View</span>
                  </v-btn>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-pagination
          v-model="paginationDeliveryTmr.current_page"
          :length="paginationDeliveryTmr.total_page"
          :total-visible="7"
          @input="fetchTmrDeliveryOrders(paginationDeliveryTmr.current_page)"
          class="float-right mt-2"
          color="primary"
        >
        </v-pagination>
      </v-col>
    </v-row>
    <v-dialog
      v-model="orderDialog"
      :width="$vuetify.breakpoint.xs?'90%':'60%'"
      persistent
    >
      <v-card>
        <v-container class="pa-0">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" class="pr-md-5">
                <v-autocomplete 
                  outlined 
                  dense
                  label="Search menu item"
                  :items="menus"
                  item-text="name"
                  item-value="id"
                  return-object
                  v-model="selectedItem"
                  disabled
                >
                </v-autocomplete>
                <v-divider class="mb-1"></v-divider>
                <p>Order Summary</p>
                <p v-if="cartItems.length === 0" class="text-center">No items selected yet.</p>
                <div 
                  class="d-flex justify-center align-center"
                  v-for="item in cartItems" 
                  :key="item.name"
                >
                  {{ item.name }}
                  <v-spacer></v-spacer>
                  <v-btn 
                    color="primary"
                    icon
                    disabled
                  >
                    <v-icon>mdi-minus-circle-outline</v-icon>
                  </v-btn>
                  <span>{{ item.qty }}</span>
                  <v-btn 
                    color="primary"
                    icon
                    disabled
                  >
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </div>
                <p class="text-right mt-2 mb-0 total-text">Total: $ {{ parseFloat(cartTotal()).toFixed(2) }}</p>
                <v-divider class="mt-1"></v-divider>
              </v-col>
              <v-divider vertical v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
              <v-col cols="12" md="6" class="pl-md-5">
                <v-text-field
                  placeholder="Name"
                  v-model="editedItem.customer_name"
                  :rules="nameRules"
                  required
                  outlined
                  dense
                  color="primary"
                  class="mb-4"
                  hide-details="auto"
                  disabled
                ></v-text-field>
                <v-text-field
                  placeholder="Phone number"
                  v-model="editedItem.customer_number"
                  :rules="numberRules"
                  required
                  outlined
                  dense
                  color="primary"
                  class="mb-4"
                  hide-details="auto"
                  disabled
                ></v-text-field>
                <v-text-field
                  :value="formattedDateAndTime"
                  placeholder="Delivery slot"
                  append-icon="mdi-calendar"
                  readonly
                  :rules="dateRules"
                  required
                  outlined
                  dense
                  color="primary"
                  class="mb-4"
                  hide-details="auto"
                  disabled
                ></v-text-field>
                <v-select
                  placeholder="Delivery method"
                  v-model="editedItem.delivery_method"
                  :items="deliveryItems"
                  :rules="deliveryRules"
                  required
                  outlined
                  dense
                  class="mb-4"
                  color="primary"
                  hide-details="auto"
                  disabled
                ></v-select>
                <v-textarea
                  v-if="editedItem.delivery_method == 'Delivery'"
                  placeholder="Delivery address"
                  v-model="editedItem.delivery_address"
                  :rules="addressRules"
                  required
                  outlined
                  dense
                  color="primary"
                  class="mb-4"
                  rows="3"
                  auto-grow
                  hide-details="auto"
                  disabled
                ></v-textarea>
                <v-select
                  placeholder="Payment method"
                  v-model="editedItem.payment_method"
                  :items="paymentItems"
                  :rules="paymentRules"
                  required
                  outlined
                  dense
                  class="mb-4"
                  color="primary"
                  hide-details="auto"
                  disabled
                ></v-select>
                <v-textarea
                  placeholder="Special requests"
                  v-model="editedItem.notes"
                  outlined
                  dense
                  color="primary"
                  class="mb-4"
                  rows="3"
                  auto-grow
                  disabled
                >
                </v-textarea>
                <v-btn 
                  depressed
                  class="mb-5 primary-border"
                  block
                  @click="cancelOrder"
                >
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import apiService from '@/services/api.service.js'

export default {
  data () {
    return {
      menus: [],
      selfPickupOrders: [],
      tmrSelfPickupOrders: [],
      deliveryOrders: [],
      tmrDeliveryOrders: [],
      cartItems: [],
      dateRangeText: '',
      dateRange: null,
      selectedItem: null,
      radioGroup: null,
      deliveryItems: [ 'Delivery', 'Self pickup' ],
      paymentItems: [ 'BIBD transfer', 'Cash' ],
      today: new Date().toISOString().split('T')[0],
      tomorrow: '',
      orderDialog: false,
      defaultItem: {
        id: '',
        customer_name: '',
        customer_number: '',
        delivery_method: null,
        delivery_address: '',
        payment_method: null,
        booking: { 
          slot: {
            slot_date: ''
          } 
        },
        order_status: { name: '' },
        notes: '',
        order_items: []
      },
      editedItem: {
        id: '',
        customer_name: '',
        customer_number: '',
        delivery_method: null,
        delivery_address: '',
        payment_method: null,
        booking: { 
          slot: {
            slot_date: ''
          } 
        },
        order_status: { name: '' },
        notes: '',
        order_items: []
      },
      mobileHeaders: [
        { text: 'Order ID', value: 'order_number', sortable: false },
        { text: 'Name', value: 'customer_name', sortable: false },
        { text: 'Phone No.', value: 'customer_number', sortable: false },
        { text: 'Status', value: 'order_status', width: '20%', sortable: false }
      ],
      statuses: {
        'new': 1,
        'confirmed': 2,
        'cancelled': 3
      },
      paginationPickup: {
        current_page: 0, 
        per: 0,
        total_count: 0,
        total_page: 0
      },
      paginationPickupTmr: {
        current_page: 0, 
        per: 0,
        total_count: 0,
        total_page: 0
      },
      paginationDelivery: {
        current_page: 0, 
        per: 0,
        total_count: 0,
        total_page: 0
      },
      paginationDeliveryTmr: {
        current_page: 0, 
        per: 0,
        total_count: 0,
        total_page: 0
      },
      nameRules: [
        value => !!value || 'Name is required.'
      ],
      deliveryRules: [
        value => !!value || 'Delivery method is required.'
      ],
      addressRules: [
        value => !!value || 'Address is required.'
      ],
      dateRules: [
        value => !!value || 'Date is required.',
      ],
      paymentRules: [
        value => !!value || 'Payment method is required.'
      ],
      numberRules: [
        value => !!value || 'Phone number is required.',
        value => !!(/^\d{7}$/.test(value)) || 'Phone number must be valid.',
      ],
      messageRules: [
        value => !!value || 'Message is required.',
      ],
    }
  },
  methods: {
     async fetchDeliveryOrders(page = null) {
      try {
        const res = await apiService.get(`/api/v1/admin/orders?page=${page}`, {
          sort: 1,
          method: 0,
          status: 2,
          start_date: new Date(new Date(this.today).setHours(0,0,0)),
          end_date: new Date(new Date(this.today).setHours(23, 59, 59))
        })
        const response = res.data
        response.data.pop()
        this.deliveryOrders = response.data
        if (!page) this.paginationDelivery = response.pagination
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the orders as this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
     async fetchTmrDeliveryOrders(page = null) {
      try {
        const res = await apiService.get(`/api/v1/admin/orders?page=${page}`, {
          sort: 1,
          method: 0,
          status: 2,
          start_date: new Date(new Date(this.tomorrow).setHours(0,0,0)),
          end_date: new Date(new Date(this.tomorrow).setHours(23, 59, 59))
        })
        const response = res.data
        response.data.pop()
        this.tmrDeliveryOrders = response.data
        if (!page) this.paginationDeliveryTmr = response.pagination
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the orders as this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async fetchSelfPickupOrders(page = null) {
      try {
        const res = await apiService.get(`/api/v1/admin/orders?page=${page}`, {
          sort: 1,
          method: 1,
          status: 2,
          start_date: new Date(new Date(this.today).setHours(0,0,0)),
          end_date: new Date(new Date(this.today).setHours(23, 59, 59))
        })
        const response = res.data
        response.data.pop()
        this.selfPickupOrders = response.data
        if (!page) this.paginationPickup = response.pagination
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the orders as this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async fetchTmrSelfPickupOrders(page = null) {
      try {
        const res = await apiService.get(`/api/v1/admin/orders?page=${page}`, {
          sort: 1,
          method: 1,
          status: 2,
          start_date: new Date(new Date(this.tomorrow).setHours(0,0,0)),
          end_date: new Date(new Date(this.tomorrow).setHours(23, 59, 59))
        })
        const response = res.data
        response.data.pop()
        this.tmrSelfPickupOrders = response.data
        if (!page) this.paginationPickupTmr = response.pagination
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the orders as this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async fetchMenu() {
      try {
        const res = await apiService.get('api/v1/menus')
        const response = res.data
        this.menus = response.data.map(menu => ({ ...menu, qty: 1 }))
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the menu at this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async updateOrderDialog(id) {
      try {
        const res = await apiService.get(`api/v1/admin/orders/${id}`)
        const response = res.data
        this.item = response.data
        if (this.item.booking != null) {
          this.editedItem.booking.slot.slot_date = this.item.booking.slot.slot_date
          this.radioGroup = this.item.booking.slot
        }
        for (let i=0; i < this.item.order_items.length; i++) {
          let menu = this.menus.find(menu => menu.name  == this.item.order_items[i].menu.name)
          menu.qty = this.item.order_items[i].quantity
          menu.amount = menu.price * menu.qty
          let menu2 = { ...menu, item_id: this.item.order_items[i].id }
          this.cartItems.push(menu2)
          menu.qty = 1
        }
        this.create = false
        this.editedItem = Object.assign({}, this.item)
        this.orderDialog = true
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the order at this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    cancelOrder() {
      this.orderDialog=false
      this.removeCart()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.booking.slot.slot_date = ''
      })
    },
    getColor(status) {
      if (status === 'new') return 'accent'
      else if (status === 'cancelled') return 'red'
      else return 'green'
    },
    notesExist(item) {
      if (![null, ''].includes(item.notes)) return true
    },
    cartTotal() {
      const total = this.cartItems.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.amount);
      }, 0);
      return total
    },
    removeCart() {
      this.cartItems = []
    },
    setTmrDate() {
      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      this.tomorrow = new Date(tomorrow).toISOString().split('T')[0]
    }
  },
  created () {
    this.setTmrDate()
    this.fetchMenu()
    this.fetchDeliveryOrders()
    this.fetchTmrDeliveryOrders()
    this.fetchSelfPickupOrders()
    this.fetchTmrSelfPickupOrders()
  },
  computed : {
    formattedDate() {
      if (!this.editedItem.booking.slot.slot_date) return null
      const [year, month, day] = this.editedItem.booking.slot.slot_date.split('-')

      return `${day}/${month}/${year}`
    },
    formattedDateAndTime() {
      if (!this.editedItem.booking.slot.slot_date || !this.radioGroup) return null
      const [year, month, day] = this.editedItem.booking.slot.slot_date.split('-')
      return `${day}/${month}/${year}  (${this.radioGroup.slot_time})`
    },
    getCurrentDate() {
      return new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
    },
    getTmrDate() {
      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      return new Date(tomorrow).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
    },
    getHeaders() {
      if (this.$vuetify.breakpoint.lg) return this.mobileHeaders
      let xsHeaders = this.mobileHeaders.filter((val, index, array) => {
        return index === 0 || index === array.length - 1
      })
      return xsHeaders
    }
  },
}
</script>

<style lang="scss">
  .mobile-table > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: #8C9B5A;
    color: white !important;
  }
  .chips {
    width: 100%
  }
</style>